import Pagination from "react-js-pagination";
import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, Route, Switch} from "react-router-dom";
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Form,
  Collapse,
  Card,
  CardImg,
  CardTitle, BreadcrumbItem, Breadcrumb, Button
} from "reactstrap";
import onImage from "../../assets/images/no-image.jpg";
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { veh_data } from "../../shared/vehicle_make_and_models";
import SlidingPane from "react-sliding-pane";
import SearchPartnerEB from "../../containers/searchResultEBContainer";
import credentials from "../../config/credentials";
// require("bootstrap/less/bootstrap.less");

class SearchItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      results: props.searchItemSuccess && props.searchItemSuccess.metadata,
      searchItemSuccesss:
        props.searchItemSuccess && props.searchItemSuccess.results,
      currentPage: 1,
      query: "",
      sortOrder: "",
      pageNumbers: [],
      dropdownOpen: false,
      makeSelected: this.props.url.sv,
      modelSelected: this.props.url.svm,
      minPrice: 0,
      maxPrice: 0,
      minValue: 0,
      maxValue: 0,
      sorted: "0",
      resultsMetadata: {},
      activePageNumber: 1,
      totalItemsEntries: 0,
      itemsPerPage: 0,
      totalPages: 1,
      isFilterPaneOpen: false,
      values: [
         0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };

    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.handlePreviousForward = this.handlePreviousForward.bind(this);
    this.toggle = this.toggle.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePriceRange = this.handlePriceRange.bind(this)
    this.handleMax = this.handleMax.bind(this);
    this.sortAscending = this.sortAscending.bind(this);
    this.sortDescending = this.sortDescending.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.paginate = this.paginate.bind(this);
    this.openFilterPanel = this.openFilterPanel.bind(this);
    this.handleFilterChanges = this.handleFilterChanges.bind(this);
  }

  componentDidMount() {

    if (this.props.isLoggedIn) {
      const token = localStorage.getItem('token')
      let userSearchQuery = {q: this.props.match.params.q}
      if (userSearchQuery['q'] === "cat-search") {
        userSearchQuery = {q: this.props.match.params.type}
      }
      const fetchLogSearchQuery = fetch(`${credentials.API_URL}/analytics/`, {
        method: 'POST',
        headers: {'Content-Type':'application/json',
                  'Authorization': `token ${token}`},
        body: JSON.stringify(userSearchQuery)
      })
      fetchLogSearchQuery.then(response => {
      return response;
      })
    }

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(true);
    let minPrice = parseInt(window.location.pathname.split('/')[3])
    let maxPrice = parseInt(window.location.pathname.split('/')[4])
    let query = window.location.pathname.split('/')[2]
    let sortOrder = window.location.pathname.split('/')[6]
    this.setState({minPrice: minPrice, maxPrice: maxPrice, query: query, sortOrder: sortOrder})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(false);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate(prevProps) {
    // window.scrollTo(0, this.topElement.offsetTop);
    if (this.props.searchResultsMetadata && prevProps.searchResultsMetadata !== this.props.searchResultsMetadata) {

      this.setState({
        activePageNumber: parseInt(this.props.searchResultsMetadata.pageNumber),
        totalItemsEntries: parseInt(this.props.searchResultsMetadata.totalEntries),
        itemsPerPage: parseInt(this.props.searchResultsMetadata.entriesPerPage),
        totalPages: parseInt(this.props.searchResultsMetadata.totalPages),
      })
    }
    if (this.props.url && prevProps.url !== this.props.url) {
      let priceFrom = this.props.url.min
      let priceTo = this.props.url.max
      let sortOrder = this.props.url.sort
      let pageNum = this.props.url.page
      this.setState({minPrice: priceFrom, maxPrice: priceTo, sortOrder: sortOrder, activePageNumber: pageNum})
    }
  }


  openFilterPanel = (e) => {
    this.setState({isFilterPaneOpen: true})
  }

  handleFilterChanges = (e) => {

    const pNum = this.state.activePageNumber
    const target = e.currentTarget.textContent
    let payload = this.props.url

    let urlParams = ("type" in payload)
      ?
      `/partner-one-search-cat/${payload.q}/${payload.min}/${payload.max}/${pNum}`
      :
      `/partner-one-search/${payload.q}/${payload.min}/${payload.max}/${pNum}`

    if (target === "Price: High to Low"){
      urlParams = `${urlParams}/d`
    }
    if (target === "Price: Low to High"){
      urlParams = `${urlParams}/a`
    }
    if (target === "Best Match"){
      urlParams = `${urlParams}/b`
    }
    if ("type" in payload) {
      urlParams = `${urlParams}/${payload.type}`
    }
    this.props.history.push(urlParams)
  }

  handlePageChange (pageNumber) {

    let pNum = pageNumber.toString()
    let payload = this.props.url

    let url = ("type" in payload)
      ?
      `/partner-one-search-cat/${payload.q}/${payload.min}/${payload.max}/${pNum}/${this.state.sortOrder}/${payload.type}`
      :
      `/partner-one-search/${payload.q}/${payload.min}/${payload.max}/${pNum}/${this.state.sortOrder}`

    this.props.history.push(url)
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  handleMakeChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${event.target.value}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.state.makeSelected}/${event.target.value}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);

    this.setState({ modelSelected: event.target.value });
  }

  handlePriceRange(event) {
    const selectPrice = event.target.name
    let q = this.state.query
    let maxPrice = this.state.maxPrice
    let pageNum = this.state.activePageNumber
    let sortOrder = this.state.sortOrder
    let min  = this.state.minPrice
    let payload = this.props.url
    let urlParams = `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`

    switch (selectPrice) {
      case 'min-price':
        min = event.target.value
        urlParams = ("type" in payload) ?
          `/partner-one-search-cat/${payload.q}/${min}/${payload.max}/${pageNum}/${sortOrder}/${payload.type}`
            :
          `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`
        this.props.history.push(urlParams)
        break;

      case 'max-price':
        maxPrice = event.target.value
        urlParams = ("type" in payload) ?
          `/partner-one-search-cat/${payload.q}/${payload.min}/${maxPrice}/${pageNum}/${sortOrder}/${payload.type}`
            :
          `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`
        this.props.history.push(urlParams)
        break;

      default:
        break;
    }
  }

  handleMax(event) {
    const query = `${this.props.url.page}/${this.state.minValue}/${
      event.target.value
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}`;

    this.props.history.push(`/search/${query}`);

    this.setState({ maxValue: event.target.value });
  }

  handleBreadCrumb(type, value) {
    let query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/any`;

    if (type === "class") {
      query = `1/0/0/${this.props.url.st}/*/*/${this.state.sorted}`;
    }
    if (type === "model") {
      query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/*/${this.state.sorted}`;
    }


    this.props.history.push(`/search/${query}`);
  }

  keyToOption(key) {
    return key
      .split("-")
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(" ");
  }


  static getDerivedStateFromProps(props, state) {
    const { searchItemSuccess } = props;
    // Logic for displaying limited searched Vehicles
    if (searchItemSuccess) {
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(searchItemSuccess.count / 25); i++) {
        pageNumbers.push(i);
      }
      return {
        results: props.searchItemSuccess.count,
        searchItemSuccess: searchItemSuccess.results,
        pageNumbers
      };
    }
    return null;
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sortAscending() {
    if (this.state.sorted === "0") {
      return
    }

    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/0`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "0"  });
  }

  sortDescending() {
    if (this.state.sorted === "1") {
      return
    }
    const query = `${this.props.url.page}/${this.props.url.min}/${this.props.url.max
                    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/1`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "1"  });

  }

  routeToDetails = (id, price) => {
    const { history } = this.props;
    history.push(`/item-details/${id}/${price}`);
  };

  renderResults(currentResult) {
    if (currentResult && currentResult.length) {
      return currentResult.map(
        ({ price, title, remote_image, fuel_type, transmission, id, listing_type }, i) => {
          return (
            <div
              className="col-12 col-md-12 col-lg-12 mt-12 search-card"
              key={i}>
              <Card onClick={() => this.routeToDetails(id, price)} key={"card-" + i} className="cardSearch">
                <Link to="#">
                  <div className="row">
                    <div style={{backgroundColor: '#fff'}} className="col col-sm-4 col-md-2 col-lg-3 search-card-img-div">
                      <CardImg
                        src={
                          remote_image !== "No_Image_found"
                            ? remote_image
                            : onImage
                        }
                        className="mx-auto car-img img-responsive card-img-top-item"
                        id="search-card-imgsdf"
                      />
                    </div>
                    <div className="col col-sm-8 col-md-6 col-lg-6 contentTitles">
                      <div className="topPriceContainer">
                        <span className="price">
                          $
                          {price
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </span>
                      </div>
                      <CardTitle className="cardSearchTitle">{title}</CardTitle>
                      <div className="container cardProperties" style={{ boxShadow: "4px 4px 1px #EEE"}}>
                        {listing_type === 'Auction' ?
                         <div>Under Auction</div>
                      :
                        <div>Available</div>
                        }
                        {transmission && ` ${transmission} `}
                        {transmission && fuel_type && "- "}
                        {fuel_type && ` ${fuel_type} `}

                      </div>
                    </div>
                    <div className="col col-sm-3 col-md-3 col-lg-3 d-flex flex-column justify-content-between align-items-center price-container">
                      <span className="price">
                        $
                        {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </span>
                      {listing_type === 'Auction' ?
                        <Link
                          to="#"
                          className="btn btn-primary d-lg-block inquiryBtn">
                          Inquire
                        </Link>
                      :
                        <Link
                          to="#"
                          className="btn btn-primary d-lg-block inquiryBtn" style={{background: 'linear-gradient(to bottom, rgb(19, 213, 68) 0%, #325507 100%)'}}>
                          Buy Now
                        </Link>
                        }
                    </div>
                  </div>
                </Link>
              </Card>
            </div>
          );
        }
      );
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12">
          <Card>No record Found</Card>
        </div>
      );
    }
  }

  handlePaginationClick(number) {
    const query = `${number}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);

    this.setState({
      currentPage: number
    });
  }

  handlePreviousForward(number, switchState) {
    const reqNumber = switchState ? this.state.currentPage + 1 : this.state.currentPage - 1
    const query = `${reqNumber}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);
    if (switchState) {
      this.setState({
        currentPage: this.state.currentPage + number
      });
    } else {
      this.setState({
        currentPage: this.state.currentPage - number
      });
    }
  }

  paginate(array, page_size, page_number) {
    --page_number
    return array.slice(page_number, (page_number + page_size));
  }

  renderPagination(pageNumbers) {
    const renderedPages = this.paginate(pageNumbers, 5, this.state.currentPage)
    return renderedPages.map(number => {
      return (
        <li
          className={`page-item${
            this.state.currentPage === number ? " active" : ""
          }`}
          key={number}
          onClick={() => this.handlePaginationClick(number)}>
          <span className="page-link">{number}</span>
        </li>
      );
    });
  }

  render() {
    const vehicles = veh_data.reduce(
      (acc, veh, i) => {
        let make = Object.keys(veh)[0];
        let vehModels = veh[make];
        return {
          makes: [
            ...acc.makes,
            <option key={make + i} value={make}>
              {this.keyToOption(make)}
            </option>
          ],
          models: {
            ...acc.models,
            [make]: [
              <option key={make + i + 0} value={0}>
                Any Model
              </option>,
              vehModels.map((model, i) => {
                return (
                  <option key={make + model + i} value={model}>
                    {this.keyToOption(model)}
                  </option>
                );
              })
            ]
          }
        };
      },
      { makes: [], models: [] }
    );


    const selectedModels =
      this.state.makeSelected && this.state.makeSelected.length ? (
        vehicles.models[this.state.makeSelected]
      ) : (
        <option disabled value={0}>
          All models
        </option>
      );

    const selectMinValues = this.state.values
      .filter((val) => {
        return val < this.state.maxPrice
      })
      .map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.values
      .filter((val) => {
        return val > this.state.minPrice
      })
      .map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const { searchItemLoader } = this.props;

    const makeSel = this.state.makeSelected
    const defaultFilterOption = makeSel === "*" ? 0 : makeSel
    const defaultMinValue = this.state.minValue === 0 ? 0 : this.state.minValue
    const defaultMaxValue = this.state.maxValue === 0 ? 0 : this.state.maxValue
    const {
      searchItemSuccess,
      width, totalItemsEntries, query
    } = this.state;

    return (

      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row">
            {searchItemLoader ? (
              <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '500px'}}>
                <Loading />
              </div>
            ) : (
              <div className="container">
                <Breadcrumb style={{marginBottom: "10px", borderBottom: "1px solid #DDD"}}>
                  <BreadcrumbItem>
                    <Link to="/" className="listedLink">
                      Home
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link
                      to="#"
                      className="listedLink"
                      // onClick={() => this.handleBreadCrumb("class", url.st)}
                    >
                        { decodeURI(query) }
                      </Link>{" "}
                  </BreadcrumbItem>
                </Breadcrumb>
                <Collapse isOpen={width > 769 || this.props.filterReducer}>
                  <div>
                    <Form>
                      <Row>
                        <Col sm={12} md={2} lg={2}>
                          <FormGroup style={{display: 'None'}}>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMakeChange(e)}
                              type="select"
                              value={defaultFilterOption}
                              name="q"
                              id="q">
                              <option disabled value={0}>
                                All makes
                              </option>
                              {vehicles.makes}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleModelChange(e)}
                              type="select"
                              value={
                                this.state.modelSelected ||
                                this.props.url.svm ||
                                "any"
                              }
                              name="m"
                              id="m">
                              {selectedModels}
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMin(e)}
                              type="select"
                              value={defaultMinValue}
                              name="m"
                              id="m">
                              <option disabled value={0}>
                                Min Price
                              </option>
                              {selectMinValues}
                            </Input>
                          </FormGroup>
                        </Col>

                        <Col sm={12} md={2} lg={2} style={{display: 'None'}}>
                          <FormGroup>
                            <Input
                              className="filter_Button"
                              onChange={e => this.handleMax(e)}
                              type="select"
                              value={defaultMaxValue}
                              name="m"
                              id="m">
                              <option disabled value={0}>
                                Max Price
                              </option>
                              {selectMaxValues}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Collapse>
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h6 className="text-left">
                      <b className="resultBold">
                        {totalItemsEntries ? totalItemsEntries : 0} results
                      </b>
                    </h6>
                    </div>
                    <div className="col" style={{textAlign: "right"}}>
                      <Button
                        size={"sm"}
                        onClick={this.openFilterPanel}
                        className="filter-button"
                        style={{color: '#6fa8dd', border: 'solid 1px #6fa8dd'}}>FILTERS</Button>
                    </div>
                  </div>
                </div>
                <hr />
                {searchItemSuccess &&
                  this.renderResults(searchItemSuccess)}
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="pagination-container mt-10 mb-10">
                    <Pagination
                    // prevPageText='prev'
                    // nextPageText='next'
                    // firstPageText='first'
                    // lastPageText='last'
                    activePage={this.state.activePageNumber}
                    itemsCountPerPage={this.state.itemsPerPage}
                    totalItemsCount={this.state.totalItemsEntries}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange.bind(this)}
                  />
                  </div>
                </div>
                <div>
                </div>
              </div>
            )}
          </div>
        </div>
        <SlidingPane
          isOpen={ this.state.isFilterPaneOpen }
          from='right'
          width='60%'
          onRequestClose={ () => this.setState({ isFilterPaneOpen: false }) }>

          <div>
            <div style={{fontSize: "14px", textAlign: "center", fontWeight: 900, paddingBottom: "10px"}}>
               FILTERS
            </div>
            <div style={{fontSize: "14px"}}>
              Sort by
            </div>
            {searchItemLoader ? (
              <div className="offset-5 offset-lg-6" style={{
                paddingTop: '30px',
                paddingBottom: '300px',
                textAlign: "center",
                marginLeft: "auto"
              }}>
                <Loading />
              </div>
            ) : (
              <div>
                <div style={{paddingTop: "15px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Price: Low to High
                      </Button>
                    </Col>
                  </Row>
                </div>
                <p style={{paddingTop: "5px", marginLeft: "3px", marginRight: "3px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Price: High to Low
                      </Button>
                    </Col>
                  </Row>
                </p>
                <p style={{paddingTop: "5px", marginLeft: "3px", marginRight: "3px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Best Match
                      </Button>
                    </Col>
                  </Row>
                </p>
                <hr/>
                <div style={{fontSize: "14px"}}>
                  Price Range
                </div>
                <div style={{paddingTop: "15px", marginLeft: "3px", marginRight: "3px"}}>
                  <Form>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                          <Label for="min-price">From</Label>
                          <Input
                            className="filter_Button"
                            onChange={e => this.handlePriceRange(e)}
                            type="select"
                            value={this.state.minPrice}
                            name="min-price"
                            id="m">
                            <option disabled value={0}>
                              Min Price
                            </option>
                            {selectMinValues}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                          <Label for="max-price">To</Label>
                          <Input
                            className="filter_Button"
                            onChange={e => this.handlePriceRange(e)}
                            type="select"
                            value={this.state.maxPrice}
                            name="max-price"
                            id="m"
                            block>
                            <option disabled value={0}>
                              Max Price
                            </option>
                            {selectMaxValues}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            )}
          </div>
        </SlidingPane>
      </div>
    );
  }
}

export default SearchItem;
