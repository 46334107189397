import React, { Component } from "react";
import SearchItem from "./SearchItemComponent"
import {Helmet} from "react-helmet";


class Home extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.props.searchItem &&
        this.props.searchItem(this.props.match.params);
    }
  }

  componentDidMount() {
    this.props.searchItem &&
      this.props.searchItem(this.props.match.params);
  }

  render() {
    const { searchItemLoader, searchItemSuccess } = this.props;
    let searchISuccess = searchItemSuccess && searchItemSuccess
    const searchResultMetadata = searchItemSuccess && searchItemSuccess.metadata
    return (
      <div>
        <Helmet>
          <meta name="description"
                content="Find best import deals on Vehicles, Auto Parts and Electronics and much more." />
          <title>Ex UK Online - Search</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding" style={{paddingLeft: "0px", paddingRight: "0px"}}>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="searchResult" style={{paddingTop: "0px"}}>
                    <SearchItem
                      searchItemLoader={searchItemLoader}
                      searchItemSuccess={searchISuccess}
                      searchResultsMetadata={searchResultMetadata}
                      url={this.props.match.params}
                      {...this.props}
                    />
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
